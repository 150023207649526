import { useTranslation } from '@mntn-dev/i18n'
import {
  Stack,
  Surface,
  type SurfaceProps,
  Text,
  getTestProps,
} from '@mntn-dev/ui-components'
import { themeBackgroundMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'
import type { PropsWithChildren } from 'react'

const testId = (id: string) => `${id}-empty-placeholder`

const NothingToSee = () => {
  const { t } = useTranslation('generic')

  return (
    <Text
      className="h-full content-center text-center"
      as="div"
      textColor="tertiary"
    >
      {t('nothing-to-see')}
    </Text>
  )
}

const CallToAction = ({
  heading,
  button,
}: { heading: React.JSX.Element; button: React.JSX.Element }) => {
  return (
    <Stack direction="col" gap="4" alignItems="center">
      <Stack direction="col" alignItems="center">
        {heading}
      </Stack>
      {button}
    </Stack>
  )
}

type EmptyStateProps = PropsWithChildren<SurfaceProps> & { id: string }

const EmptyStateComponent = ({
  id,
  children,
  className,
  ...props
}: EmptyStateProps) => {
  return (
    <Surface
      {...props}
      padding="20"
      width="full"
      height="full"
      {...getTestProps({
        dataTestId: testId(id),
        dataTrackingId: testId(id),
      })}
      className={cn(themeBackgroundMap['container-tertiary'], className)}
    >
      {children}
    </Surface>
  )
}

const EmptyStateNamespace = Object.assign(EmptyStateComponent, {
  NothingToSee,
  CallToAction,
})

export { EmptyStateNamespace as EmptyState }
